export const environment: any = {
    production: true,
    port: 4000,
    apiUrl: 'https://stg.api2.abonnementmag.fr',
    frontUrl: 'https://stg.abonnementmag.fr',
    stripe : {
	publicKey: 'pk_test_515akJxD5CTJnsm1czcTMpbp7fF6kezseheqAkZHD1qMniRqF0VDyQUJcM9umlj5dCm1ihHP1VHK4NbFf9jv5ycBT004IzG06S1',
    },
    apiTokenLogin: {
        username: 'burda',
        password: 'NyzHusHr8A8YTWUx',
    },
    googleReCaptchaKey: '6Lf-aq0kAAAAAMOXQCn2l7dTc6YswKFOPYwCSv11',
    googleAddressKey: 'AIzaSyAlGRYY4RPmclJTRxMeNh60HXhoUrfQ-CQ',
    immanensPv5api: 'https://pressview5-beta.immanens.com/api'
};
